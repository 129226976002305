<template lang="">
    <div class="field">
        <div class="grid formgrid mt-6 px-4">
            <div class="field col-6">
                <label>Selecione os cadastros que serão importados</label>
                <div class="formgrid grid">
                    <div class="field col">
                        <MultiSelect
                            id="cadastros"
                            class="cadastros"
                            :selectAll="selectAll"
                            @selectall-change="selectAllCadastro($event)"
                            :modelValue="formData.cadastros"
                            @change="handleChangeCad"
                            dataKey="id"
                            :optionDisabled="checkDisabledOptions"
                            :options="formData.cadastrosLista"
                            placeholder="Selecione os cadastros que serão importados"
                            optionLabel="descricao"
                            :class="{ 'p-invalid': submitted && formData.cadastros.length <= 0 }"
                        />
                        <small class="p-error" v-if="submitted && formData.cadastros.length <= 0">Cadastro é obrigatório.</small>
                    </div>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button label="Voltar" class="p-button-outlined p-button-primary mr-2 w-15rem" @click="prevPage()" />
                        <Button label="Próximo" class="p-button p-button-primary w-15rem" @click="nextPage()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        formData: Object
    },
    data() {
        return {
            submitted: false,
            validationErrors: {},
            selectAll: false
        };
    },
    methods: {
        handleChangeCad(e) {
            this.$emit('setFormData', e.value);
            this.selectAll = event.value.length === this.formData.cadastros.length;
        },
        checkDisabledOptions(option) {
            if (option.id === 'CL') {
                return true;
            }
            return false;
        },
        selectAllCadastro(event) {
            const cadastros = event.checked
                ? this.formData.cadastrosLista.map((item) => item)
                : this.formData.tipoImportacao === 'NC'
                ? [{ id: 'CL', descricao: 'Cliente' }]
                : [];
            this.$emit('setFormData', cadastros);
            this.selectAll = event.checked;
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {
                    formData: {
                        cadastros: this.formData.cadastros,
                        step: 3,
                        link: [...this.formData.link, '/importacao-cadastro-estrutura/selecao-cadastro']
                    },
                    pageIndex: 1
                });
            }
        },
        prevPage() {
            this.$emit('prev-page', { formData: { pageIndex: 1, step: 1 } });
        },
        validateForm() {
            if (!this.formData.cadastros || this.formData.cadastros?.length <= 0) this.validationErrors['cadastros'] = true;
            else delete this.validationErrors['cadastros'];

            return !Object.keys(this.validationErrors).length;
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep(.cadastros) {
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
}
</style>
